.App {
  text-align: center;
  display: flex;
}
.scrollable-content {
  overflow-y: scroll;
  height: 400px;
}

.blogCase{
  height: auto;
  padding: 15px;
  background-color: #d0d0d062;
  border-radius: 25px;
  text-align: center;
  color: white;
  line-height: 2;
}

.curriculum{
  border-radius: 25px;
  padding: 15px;
  background-color: #d0d0d062;
  height: auto;
  text-align: center;
  margin: 10px;
  color: white;
}
.projects{
  background-color: #d0d0d062;
  border-radius: 25px;
}
.Secondary{
  color: #6ACEB9;
}