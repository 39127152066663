.img {
    animation: fadeIn 5s;
    margin: 2px;
    width: 300px;
    border-radius: 50%;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.gradient{
    animation: fadeIn 5s;
}